import React, {FC} from 'react'
import { motion } from "framer-motion"
import styles from './Prices.module.scss'
import { Link } from 'react-router-dom'



const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


export const Prices:FC = () => {
    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}} 
            >
                <motion.h2 className={styles.title} variants={titleAnimation}>Мои услуги по созданию<span> продающих&#160;сайтов</span></motion.h2>
                <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item_services}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_price}>
                            <h3 className={styles.item_title}>Landing Page</h3>
                            <p className={styles.item_subtitle}>От <span>15 000₽</span></p>
                            <p className={styles.item_subtitle}>от <span>5</span> дней</p>
                            <p className={styles.item_text}>Продающий лендинг- самый востребованный продукт. Благодаря продуманным маркетингом и триггерами Вы будете получать целевые заявки.</p>
                            <div className={styles.item_bonus}>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Эксклюзивный дизайн</p>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Гарантия 12 месяцев</p>
                            </div>
                        <Link className={styles.link} to='/landing-page'>Узнать больше <span className={styles.glare}></span></Link>
                        </div>
                        <article className={styles.shadow}></article>
                    </motion.li>
                    <motion.li className={styles.item_services_one}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                    >
                        <div className={styles.item_price}>
                            <h3 className={styles.item_title}>Сайт-визитка</h3>
                            <p className={styles.item_subtitle}>От <span>15 000₽</span></p>
                            <p className={styles.item_subtitle}>от <span>5</span> дней</p>
                            <p className={styles.item_text}>Сайт визитка - это своеобразная "начальная ступень", с которой удобно начитать продвижение информации о вашем деле.</p>
                            <div className={styles.item_bonus}>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Эксклюзивный дизайн</p>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Гарантия 12 месяцев</p>
                            </div>
                            <Link className={styles.link} to='/sayt-vizitka'>Узнать больше <span className={styles.glare}></span></Link>
                        </div>
                        <article className={styles.shadow}></article>
                    </motion.li>
                    <motion.li 
                        className={styles.item_services_two}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_price}>
                            <h3 className={styles.item_title}>Персональный сайт</h3>
                            <p className={styles.item_subtitle}>От <span>15 000₽</span></p>
                            <p className={styles.item_subtitle}>от <span>5</span> дней</p>
                            <p className={styles.item_text}>Создание сайта,  который поможет заявить о себе как о специалисте и рассказать о вашем бизнесе, услуге или продукте.</p>
                            <div className={styles.item_bonus}>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Эксклюзивный дизайн</p>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Гарантия 12 месяцев</p>
                            </div>
                            <Link className={styles.link} to='/personalnyy-sayt'>Узнать больше <span className={styles.glare}></span></Link>
                        </div>  
                        <article className={styles.shadow}></article>
                    </motion.li>
                    <motion.li 
                        className={styles.item_services_three}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                            <div className={styles.item_price}>
                                <h3 className={styles.item_title}>Корпоративный сайт</h3>
                                <p className={styles.item_subtitle}>От <span>50 000₽</span></p>
                                <p className={styles.item_subtitle}>от <span>10</span> дней</p>
                                <p className={styles.item_text}>Создание массивного сайта, совмещающего в себе презентацию вашей компании и ее успехов, а также включающего каталог товаров и услуг.</p>
                                <div className={styles.item_bonus}>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Эксклюзивный дизайн</p>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Гарантия 12 месяцев</p>
                            </div>
                                <Link className={styles.link} to='/korporativnyy-sayt'>Узнать больше <span className={styles.glare}></span></Link>
                            </div>
                        <article className={styles.shadow}></article>
                    </motion.li>
                    <motion.li 
                        className={styles.item_services_four}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                            <div className={styles.item_price}>
                                <h3 className={styles.item_title}>Интернет-магазин</h3>
                                <p className={styles.item_subtitle}>От <span>90 000₽</span></p>
                                <p className={styles.item_subtitle}>от <span>30</span> дней</p>
                                <p className={styles.item_text}>Создание мощной и быстрой площадки для онлайн-продаж ваших товаров с каталогом, корзиной и онлайн-оплатой.</p>
                                <div className={styles.item_bonus}>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Эксклюзивный дизайн</p>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Гарантия 12 месяцев</p>
                            </div>
                                <Link className={styles.link} to='/internet-magazin'>Узнать больше <span className={styles.glare}></span></Link>
                            </div>
                        <article className={styles.shadow}></article>
                    </motion.li>
                    <motion.li className={styles.item_services_five}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                            <div className={styles.item_price}>
                                <h3 className={styles.item_title}>Поддержка сайта</h3>
                                <p className={styles.item_subtitle}>От <span>2 000₽</span></p>
                                <p className={styles.item_subtitle}>за <span>1</span> час</p>
                                <p className={styles.item_text}>Техническая поддержка сайта необходима, так как он должен быть доступен пользователям постоянно без сбоев.</p>
                                <div className={styles.item_bonus}>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Эксклюзивный дизайн</p>
                                <p className={styles.item_bonus_text}><span>&#10003;</span> Гарантия 12 месяцев</p>
                                </div>
                                <Link className={styles.link} to='/podderzhka-sayta'>Узнать больше <span className={styles.glare}></span></Link>
                            </div>                       
                        <article className={styles.shadow}></article>
                    </motion.li>
                </motion.ul>
        </motion.section>
    )
}